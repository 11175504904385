// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-annual-reports-js": () => import("./../../../src/pages/annual-reports.js" /* webpackChunkName: "component---src-pages-annual-reports-js" */),
  "component---src-pages-digital-projects-js": () => import("./../../../src/pages/digital-projects.js" /* webpackChunkName: "component---src-pages-digital-projects-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-emerging-writers-js": () => import("./../../../src/pages/emerging-writers.js" /* webpackChunkName: "component---src-pages-emerging-writers-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-gertrude-editions-js": () => import("./../../../src/pages/gertrude-editions.js" /* webpackChunkName: "component---src-pages-gertrude-editions-js" */),
  "component---src-pages-impact-js": () => import("./../../../src/pages/impact.js" /* webpackChunkName: "component---src-pages-impact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partnerships-js": () => import("./../../../src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-public-program-js": () => import("./../../../src/pages/public-program.js" /* webpackChunkName: "component---src-pages-public-program-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-publishing-js": () => import("./../../../src/pages/publishing.js" /* webpackChunkName: "component---src-pages-publishing-js" */),
  "component---src-pages-residencies-js": () => import("./../../../src/pages/residencies.js" /* webpackChunkName: "component---src-pages-residencies-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-studios-js": () => import("./../../../src/pages/studios.js" /* webpackChunkName: "component---src-pages-studios-js" */),
  "component---src-pages-supporters-js": () => import("./../../../src/pages/supporters.js" /* webpackChunkName: "component---src-pages-supporters-js" */),
  "component---src-pages-visit-us-js": () => import("./../../../src/pages/visit-us.js" /* webpackChunkName: "component---src-pages-visit-us-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-digital-project-js": () => import("./../../../src/templates/digital-project.js" /* webpackChunkName: "component---src-templates-digital-project-js" */),
  "component---src-templates-edition-js": () => import("./../../../src/templates/edition.js" /* webpackChunkName: "component---src-templates-edition-js" */),
  "component---src-templates-exhibition-js": () => import("./../../../src/templates/exhibition.js" /* webpackChunkName: "component---src-templates-exhibition-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-program-js": () => import("./../../../src/templates/program.js" /* webpackChunkName: "component---src-templates-program-js" */),
  "component---src-templates-public-program-js": () => import("./../../../src/templates/public-program.js" /* webpackChunkName: "component---src-templates-public-program-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/publication.js" /* webpackChunkName: "component---src-templates-publication-js" */)
}

